
















































































































































































































































































import {
  SfLoader,
  SfNotification,
  SfSidebar,
  SfHeading,
  SfButton,
  SfProperty,
  SfPrice,
  SfCollectedProduct,
  SfQuantitySelector,
  SfBadge,
  SfImage,
} from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  ref,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import CouponCode from './CouponCode.vue';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import {
  useUiState,
  useUiNotification,
  useExternalCheckout,
  useImage,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';
import type { ConfigurableCartItem, BundleCartItem, CartItemInterface } from '~/modules/GraphQL/types';
import { ProductStockStatus } from '~/modules/GraphQL/types';
import { Logger } from '~/helpers/logger';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    SfLoader,
    SfNotification,
    SfSidebar,
    SfButton,
    SfHeading,
    SfProperty,
    SfPrice,
    SfCollectedProduct,
    SfQuantitySelector,
    SfBadge,
    CouponCode,
    SvgImage,
    SfImage,
  },
  setup() {
    const { app: { i18n } } = useContext();
    const { initializeCheckout } = useExternalCheckout();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const { getMagentoImage, imageSizes } = useImage();
    const router = useRouter();
    const { app } = useContext();
    const {
      cart,
      removeItem,
      updateItemQty,
      load: loadCart,
      loading,
    } = useCart();

    const { isAuthenticated } = useUser();
    const { send: sendNotification, notifications } = useUiNotification();

    const products = computed(() => cartGetters
      .getItems(cart.value)
      .filter(Boolean)
      .map((item) => ({
        ...item,
        product: {
          ...item.product,
          ...[(item as ConfigurableCartItem).configured_variant ?? {}],
          original_sku: item.product.sku,
        },
      })));
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const discount = computed(() => -cartGetters.getDiscountAmount(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const getAttributes = (product: ConfigurableCartItem) => (product.configurable_options || []);
    const getBundles = (product: BundleCartItem) => product.bundle_options?.map((b) => b.values).flat() || [];
    const visible = ref(false);
    const tempProduct = ref();

    onMounted(() => {
      if (!cart.value.id) {
        loadCart();
      }
    });

    const goToCheckout = async () => {
      const redirectUrl = initializeCheckout({ baseUrl: '/checkout/user-account' });

      try {
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: 'begin_checkout',
          ecommerce: {
            value: cart?.value?.prices?.subtotal_excluding_tax?.value,
            currency: app.$vsf.$magento.config.state.getCurrency() ?? 'USD',
            items: products.value.map(product => {
              return {
                item_name: product?.product?.name,
                item_id: product?.product?.sku,
                price: product?.prices?.row_total?.value,
                quantity: product?.quantity,
                // @ts-ignore
                item_variant: product?.configured_variant?.sku
              };
            })
          }
        });
      } catch (e) {
        Logger.error(e);
      }

      await router.push(app.localePath(redirectUrl));
    };

    const sendToRemove = ({ product }: { product: CartItemInterface }) => {
      if (notifications.value.length > 0) {
        notifications.value[0].dismiss();
      }

      visible.value = true;
      tempProduct.value = product;
    };

    const actionRemoveItem = async (product: CartItemInterface) => {
      await removeItem({ product });
      visible.value = false;

      app.$gtm.push({
        event: 'remove_from_cart',
        ecommerce: {
          value: product?.prices?.row_total?.value,
          currency: app.$vsf.$magento.config.state.getCurrency() ?? 'USD',
          items: [{
            item_name: product?.product?.name,
            item_id: product?.product?.sku,
            price: product?.prices?.row_total?.value,
            quantity: product?.quantity,
            // @ts-ignore
            item_variant: product?.configured_variant?.sku
          }]
        }
      });

      sendNotification({
        id: Symbol('product_removed'),
        message: i18n.t('{0} has been successfully removed from your cart', {
          0: cartGetters.getItemName(
            product,
          ),
        }) as string,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Product removed',
      });
    };
    const delayedUpdateItemQty = debounce(
      (params) => updateItemQty(params),
      1000,
    );
    const isInStock = (product: CartItemInterface) => cartGetters.getStockStatus(product) === ProductStockStatus.InStock;

    return {
      sendToRemove,
      actionRemoveItem,
      loading,
      isAuthenticated,
      products,
      removeItem,
      delayedUpdateItemQty,
      isCartSidebarOpen,
      notifications,
      visible,
      tempProduct,
      toggleCartSidebar,
      goToCheckout,
      totals,
      totalItems,
      cartGetters,
      getAttributes,
      getBundles,
      isInStock,
      imageSizes,
      getMagentoImage,
      discount,
    };
  },
});
